import { watch, ref } from "vue";
import { useUserStore } from "~/store/user";

const useQrCodeError = () => {
  const userStore = useUserStore();
  const error = ref(false);
  const reloadAfterError = ref(false);

  watch(
    () => userStore.status.qrcode,
    (next, prev) => {
      reloadAfterError.value = next === "pending" && prev === "error";
    },
  );

  watch(
    () => userStore.status.qrcode,
    (next) => {
      error.value = next === "error" && !userStore.qrcode;
    },
  );

  return { error, reloadAfterError };
};

export default useQrCodeError;
